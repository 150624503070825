@use "import/var";
@use "import/mixin_common";
@use "import/mixin_project";
@use "import/animation";

/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/

.root {
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	top: 0;
	left: 0;

	&._bg {
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			margin: 0 calc(50% - 50vw);
			width: 100vw;
			height: 100%;
			background-color: #11111120;
			pointer-events: none;
			z-index: 1;
		}
	}
}

@mixin container($width: var.$pc_container_width) {
	width: 100%;
	box-sizing: border-box;
	padding-left: var.$pc_padding;
	padding-right: var.$pc_padding;
	margin-left: auto;
	margin-right: auto;
	max-width: $width;
}

.pc_container,
.ipc_container,
.pc_tb_container,
.container {
	@include container;

	&._relative {
		position: relative;
		top: 0;
		left: 0;
	}

	&._md {
		@include container(1000px + var.$pc_padding * 2);
	}

	&._sm {
		@include container(600px);
	}

	&._sm2 {
		@include container(300px);
	}
}

.pc_padding,
.padding {
	padding-left: var.$pc_padding;
	padding-right: var.$pc_padding;
}

.main_flex_area {
	position: relative;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;

	> * {
		min-width: 0;
		max-width: 100%;
		width: 100%;
	}

	&._department_detail {
		padding-top: 20px;
	}
}

.main_column {
	order: 1;
	width: 100%;
	box-sizing: border-box;
	padding-left: 60px;

	@media #{var.$lg} {
		padding-left: 30px;
	}
}

.sub_column {
	order: 2;
	width: 280px;
	flex-shrink: 0;
	overflow: hidden;
	box-sizing: border-box;
}

.main_content {
	font-size: var.$pc_default_font_size;
	margin-bottom: 120px;
	//padding-bottom: 80px;
}

.scroll_to_top {
	right: 0;
	// right: 0;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

*/
.pc_none,
.root .pc_none {
	display: none;
}

.block,
.pc_block,
.ipc_block {
	display: block;
}

.xs_block {
	display: none;
}

@media screen and (width >= 1200px), print {
	.lg_block {
		display: block;
	}
}

@media screen and (width >= 1000px) and (width <= 1099px), print {
	.pc_s_block {
		display: block;
	}
}

.tel_link,
.tel_link-no {
	pointer-events: none;
	cursor: default;
	color: inherit;

	.root &,
	.root &:hover {
		text-decoration: none;
		color: inherit;
	}
}

.sp_click,
.pc_no_click {
	pointer-events: none;
	cursor: default;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************



## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/

/**************************

## - 段組設定 2列 3列 4列

PCの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .pc_column2**

	<ul class="pc_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .pc_column3**

	<ul class="pc_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .pc_column4**

	<ul class="pc_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/

.pc_column2 {
	@include mixin_common.columns(2, 20px);
}

.pc_column3 {
	@include mixin_common.columns(3, 20px);
}

.pc_column4 {
	@include mixin_common.columns(4, 20px);
}

.pc_column2b {
	@include mixin_common.columnsb(2, 30px);
}

.pc_column3b {
	@include mixin_common.columnsb(3, 20px);
}

.pc_column4b {
	@include mixin_common.columnsb(4, 20px);
}

.pc_column2,
.pc_column3,
.pc_column4 {
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		vertical-align: bottom;
	}
}

.set {
	display: flex;
	justify-content: space-between;
	width: 100%;

	> * {
		flex-grow: 5;

		&:not(.fix) {
			margin-top: -10px;
		}
	}

	> .left {
		order: 1;
	}

	> .right {
		order: 2;
	}

	> .fix {
		flex-shrink: 0;
		flex-grow: 0;
		width: 100%;
	}

	> .flex {
		display: flex;
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/
.header {
	height: var.$pc_header_height;
	// backdrop-filter: blur(5px) brightness(105%);
	backdrop-filter: blur(10px) brightness(107%);
	background-color: rgb(255 255 255);
	color: #111111;
	// border-radius: 20px 20px 0 0;
	border-radius: 20px;
	box-sizing: border-box;
	box-shadow: 4px 5px 8px 0 #6f859b20;
	transition: top 0.2s;

	&._scroll {
	}

	&._open_search {
		//top: 20px;
		top: 64px;
		border-radius: 0;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;

		.header__search_input_area {
			opacity: 1;
			pointer-events: all;
		}
	}

	@supports not (backdrop-filter: blur(30px)) {
		// background: rgba(255, 255, 255, .8);
		background-color: red;
	}

	&:not(._scroll) {
		&._show_sub_menu {
			border-radius: 0;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			border-bottom: 1px solid #eeeeee;

			.header__gnav {
				> div {
					//margin-bottom: 14px;

					@media #{var.$lg2} {
						//margin-bottom: 21px;
					}
				}
			}
		}
	}

	&__inner {
	}

	&__t {
		display: flex;
		justify-content: space-between;
		padding-bottom: 10px;
		padding-left: 30px;
		padding-right: 30px;

		.header__l {
			flex-shrink: 0;
		}

		.header__r {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	&__b {
		display: flex;
		justify-content: center;
	}

	&__r {
		width: 100%;
	}

	&__logo {
		width: 445px;
		margin-right: 50px;

		@media #{var.$lg2} {
			// width: 350px;
			// margin-right: 30px;
		}

		@media #{var.$lg1260} {
			width: 300px;
			margin-right: 20px;
		}

		@media #{var.$lg} {
			width: 270px;
			margin-right: 15px;
		}
	}

	&__action_list {
		display: flex;
		align-items: center;

		> li {
			display: flex;
			align-items: center;
			padding-left: 5px;
			padding-right: 3px;
			position: relative;

			&:not(:last-of-type) {
				margin-right: 12px;

				@media #{var.$lg2} {
					margin-right: 10px;
				}

				@media #{var.$lg1260} {
					margin-right: 5px;
				}
			}

			&:last-of-type {
				margin-right: 33px;

				@media #{var.$lg2} {
					margin-right: 25px;
				}

				@media #{var.$lg1260} {
					margin-right: 20px;
				}

				@media #{var.$lg} {
					margin-right: 5px;
				}
			}

			span {
				line-height: 1.2;
				letter-spacing: 0.05em;
				color: #111111;
				font-size: var.$fontsize14;
				margin-right: 8px;

				@media #{var.$lg} {
					font-size: var.$fontsize13;
					margin-right: 5px;
					letter-spacing: 0.03em;
				}
			}

			&._language {
				a {
					position: relative;
					display: flex;
					align-items: center;
					color: #111111;
				}

				img {
					width: 33px;
					height: auto;

					@media #{var.$lg} {
						width: 28px;
					}
				}
			}

			&._font_size {
				display: flex;
				align-items: center;

				.fontsize_list {
					display: flex;
					align-items: center;

					> ._s,
					> ._l {
						position: relative;

						&:not(._selected) {
							a {
								position: relative;
								width: 33px;
								height: 33px;
								background-color: transparent;
								border-radius: 50%;
								color: transparent;
								// text-fill-color: transparent;
								background-image: linear-gradient(to right, #2a8b8e, #61a256);
								background-clip: text;
								-webkit-text-fill-color: transparent;
								z-index: 100;
								cursor: pointer;
								transition: opacity 0.2s;

								@media #{var.$lg} {
									width: 29px;
									height: 29px;
								}

								&:hover {
									opacity: 0.7;
								}
							}

							&::before {
								content: "";
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								width: 31px;
								height: 30px;
								background: #ffffff;
								border-radius: 50%;
								z-index: 5;

								@media #{var.$lg} {
									width: 27px;
									height: 27px;
								}
							}

							&::after {
								content: "";
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								width: 33px;
								height: 33px;
								background: linear-gradient(to right, #2a8b8e, #61a256);
								border-radius: 50%;
								z-index: 1;

								@media #{var.$lg} {
									width: 29px;
									height: 29px;
								}
							}
						}

						&._selected {
							a {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 30px;
								height: 30px;
								border-radius: 50%;
								background-color: #e7edf1;

								@media #{var.$lg} {
									width: 27px;
									height: 27px;
								}
							}
						}
					}

					> ._s {
						margin-right: 10px;
					}
				}
			}

			&._search {
				margin-left: 5px;

				> a {
					transition: 0.2s;

					span {
						display: flex;
						align-items: center;

						&::after {
							display: block;
							content: "";
							width: 33px;
							height: 33px;
							background: url("../img/icon/search.webp");
							background-size: contain;
							margin-left: 8px;
						}
					}

					&:hover {
						opacity: 0.7;
					}
				}

				&.hidden {
					> span {
						&::after {
							display: none;
						}
					}
				}
			}

			&:hover {
				//box-shadow: 0 0 5px rgb(0 0 0 / 30%);

				.action_list_detail {
					display: block;
				}
			}
		}
	}

	&__search_input_area {
		background-color: #439574;
		height: 64px;
		width: 100%;
		position: absolute;
		top: -64px;
		left: 0;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		transition: opacity 0.2s;
		opacity: 0;
		pointer-events: none;

		.container {
			display: flex;
			justify-content: space-between;
			height: 64px;
			align-items: center;

			> * {
				min-width: 0;
				max-width: 100%;
				width: 100%;
			}

			> .left {
				.search {
					&__text {
						color: #ffffff;
						background-color: #0d3b40;
						border: 0 none;
						padding: 0 10px 0 65px;

						&:focus {
							outline: 1px rgba(#ffffff, 0.2) solid;
						}
					}

					&__submit {
						right: auto;
						left: 0;
						border-left: 0 none;
						background-image: url("../img/icon/header_search_input.png");
						background-size: contain;
						width: 54px;
						height: 40px;
					}
				}
			}

			> .right {
				flex: 0 0 64px;

				a {
					display: block;
					width: 64px;
					height: 64px;
					position: relative;
					top: 0;
					left: 0;

					img {
						display: block;
						width: 25px;
						height: 25px;
						position: absolute;
						top: 50%;
						left: 50%;
						margin-top: -12px;
						margin-left: -12px;
					}
				}
			}
		}
	}

	&__link_list {
		display: flex;
		align-items: center;

		> li {
			width: 130px;

			&._department {
				@media #{var.$lg2} {
					width: 115px;
				}

				@media #{var.$lg} {
					width: 115px;
				}
			}

			@media #{var.$lg2} {
				width: 100px;
			}

			@media #{var.$lg} {
				width: 95px;
			}

			&:not(:last-of-type) {
				margin-right: 8px;
			}

			a {
				height: 34px;
				padding: 9px 5px;
				display: block;
				text-align: center;
				letter-spacing: 0.05em;
				line-height: 1.2;
				color: #213d2c;
				background-color: #eef2f0;
				box-sizing: border-box;
				font-size: var.$fontsize14;
				border-radius: 17px;
				transition: color, background-color 0.3s;

				@media #{var.$lg} {
					font-size: var.$fontsize13;
					border-radius: 15px;
				}

				&:hover {
					background-color: #213d2c;
					color: #eef2f0;
				}
			}
		}
	}
}

.gnav {
	//$height: 22px;
	//$count: 7;

	&__ul {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;

		> * {
			min-width: 0;
			max-width: 100%;
		}
	}

	&__li {
		flex-grow: 10;
		display: flex;
		position: relative;
		pointer-events: none;
		// width: 100%;

		&:not(:last-child) {
			&::after {
				content: "";
				display: block;
				width: 1px;
				//height: 100%;
				height: 32px;
				position: absolute;
				top: 0;
				//top: 50%;
				right: 0;
				background: #b2b2b2;
				//transform: translateY(-50%);
			}
		}

		&:first-of-type > a {
			padding-left: 45px;

			@media #{var.$lg2} {
				padding-left: 40px;
			}

			@media (width <= 1200px) {
				padding-left: 15px;
			}
		}

		&:last-of-type > a {
			padding-right: 45px;

			@media #{var.$lg2} {
				padding-right: 40px;
			}

			@media (width <= 1200px) {
				padding-right: 15px;
			}
		}
	}

	&__a {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #111111;
		line-height: 1.4;
		letter-spacing: 0.05em;
		text-align: center;
		font-size: 19px;
		//padding: 2px 44px;
		padding: 2px 30px 15px;
		pointer-events: all;
		transition: font-weight 0.2s;
		width: 100%;

		&::after {
			content: "";
			flex-shrink: 0;
			display: inline-block;
			background: url("../img/icon/arrow/circle_blue_02.webp") 0 0 no-repeat transparent;
			background-size: 24px 24px;
			width: 24px;
			height: 24px;
			margin-left: 13px;
			transition: transform 0.2s;
		}

		@media #{var.$lg2} {
			padding: 2px 20px;
			font-size: 17px;
		}

		@media #{var.$lg} {
			padding: 2px 15px;
			font-size: 15px;
		}

		&:hover {
			color: #439574;

			&::after {
				transform: translateX(6px);
			}
		}
	}
}

.gnav_sub_area {
	//left: 0;
	z-index: 1;
	height: 0;
	overflow: hidden;
	box-sizing: border-box;
	background-color: #ffffff;
	//margin: 0 calc(50% - 50vw);
	border-radius: 0 0 20px 20px;

	.root .header & {
		//width: 100%;
	}

	.container {
		padding-left: 60px;
		padding-right: 60px;
		max-width: 1440px;

		@media #{var.$lg} {
			padding: 0 40px;
		}

		@media (width <= 1173px) {
			padding: 0 30px;
		}

		&::before {
			content: "";
			position: absolute;
			top: 1px;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: #eeeeee; /* borderの色を指定 */
		}
	}

	&._show {
		height: auto;
		display: block;
		animation-name: fade-in;
		animation-duration: 0.3s;
		animation-timing-function: var.$easeOutCubic;
		animation-fill-mode: forwards;
		background-color: #ffffff;
	}

	&._leave {
		height: auto;
		display: block;
		animation-name: fade-out;
		animation-duration: 0.2s;
		animation-timing-function: var.$easeOutCubic;
		animation-fill-mode: forwards;
	}
}

.gnav_sub {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	padding-top: 5px;
	padding-bottom: 30px;

	// &::before{
	// 	content: "";
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 1px;
	// 	background-color:#eeeeee; /* borderの色を指定 */
	// }

	&__l {
		// background-color: rgba(red, .2);
		flex-shrink: 0;
		width: 320px;
		padding-top: 70px;
		padding-right: 55px;
		border-right: 1px solid #ededed;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		@media #{var.$lg2} {
			padding-right: 35px;
			width: 300px;
		}

		@media #{var.$lg1260} {
			padding-right: 25px;
			width: 270px;
		}

		.title {
			margin-bottom: 30px;

			a {
				display: inline-block;
				font-size: var.$fontsize23;
				font-weight: bold;
				letter-spacing: 0.05em;
				border-bottom: 2px solid #111111;
				padding-bottom: 20px;
				margin-bottom: 15px;
				width: 100%;
				color: #111111;

				@media #{var.$lg1260} {
					font-size: var.$fontsize20;
				}

				&:hover {
					opacity: 0.6;
				}
			}

			.ja {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: var.$fontsize23;
				font-weight: bold;
				letter-spacing: 0.05em;
				border-bottom: 1px solid #000000;
				padding-bottom: 20px;
				margin-bottom: 15px;
				width: 100%;

				&::after {
					content: "";
					display: inline-block;
					background: url("../img/icon/arrow/circle_blue.webp") 0 0 no-repeat transparent;
					background-size: 33px 33px;
					width: 33px;
					height: 33px;
				}
			}

			.en {
				@include mixin_project.roboto;

				letter-spacing: 0.05em;
				font-size: var.$fontsize20;
				text-align: end;
				background-image: linear-gradient(to right, #2a8b8e, #61a256);
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
			}
		}

		.gnav_close_btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 220px;
			margin: 0 auto;
			border: 1px solid #dfe5ed;
			border-radius: 35px;
			padding: 15px 10px;
			color: #111111;
			box-sizing: border-box;
			transition: background-color 0.2s;

			&::before {
				content: "";
				display: inline-block;
				background: url("../img/icon/close.webp") 0 0 no-repeat transparent;
				background-size: 20px 20px;
				width: 20px;
				height: 20px;
				margin-right: 15px;
			}

			&:hover {
				background-color: #e8ecf1;
			}
		}
	}

	&__r {
		width: 100%;
		// background-color: rgba(blue, .2);
		// margin-left: 60px;
		box-sizing: border-box;
	}

	&__list {
		font-size: 0;
		// width: 100%;
		//column-count: 3;
		// column-fit: auto;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 55px;

		@media #{var.$lg2} {
			column-gap: 35px;
		}

		@media #{var.$lg1260} {
			column-gap: 20px;
		}

		.li1 {
			// margin-bottom: 12px;
			//padding-left: 10px;
			//padding-right: 10px;
			// width: 33.33%;

			&._menu {
				width: 50%;
			}

			&-attempt {
				display: none;
			}
		}

		.a1 {
			font-size: var.$fontsize16;
			display: flex;
			align-items: center;
			// display: inline-block;
			color: #111111;
			position: relative;
			top: 0;
			left: 0;
			line-height: 1.3;
			letter-spacing: 0;
			transition: background-color 0.2s var.$easeOutQuint;
			padding-top: 18px;
			padding-bottom: 18px;
			border-bottom: 1px solid #ededed;

			@media #{var.$lg1260} {
				font-size: var.$fontsize15;
			}

			&:hover {
				._inner {
					&::after {
						transform: translateX(8px);
					}
				}
			}

			.gnav_block {
				display: block;
			}

			&.ext {
				> ._inner {
					&::after {
						display: none;
					}
				}
			}
		}

		.icon {
			width: 45px;
			flex-shrink: 0;
			margin-right: 18px;
		}

		._inner {
			letter-spacing: 0.05em;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			&::after {
				content: "";
				flex-shrink: 0;
				display: inline-block;
				background: url("../img/icon/arrow/circle_blue.webp") 0 0 no-repeat transparent;
				background-size: 22px 22px;
				width: 22px;
				height: 22px;
				transition: transform 0.2s;
				margin-left: 10px;
			}
		}

		.js-accordion_toggle,
		.sub_menu__ul2 {
			display: none;
		}

		&._dock {
			> .li1 {
				width: 100%;

				&:not(:last-child) {
					margin-bottom: 25px;
				}
			}

			.sub_menu__ul2 {
				// display: block;
				padding-left: 7px;
				display: flex;
				flex-wrap: wrap;
				padding-top: 5px;

				.li2 {
					margin-top: 3px;
					min-width: 25%;
					box-sizing: border-box;
					padding-right: 20px;
				}

				.a2 {
					font-size: var.$fontsize15;
					color: #ffffff;
					position: relative;
					top: 0;
					left: 0;
					padding-left: 26px;

					&::before {
						@include mixin_common.bg-inline("../img/icon/arrow/link3.webp", 18px, 18px);

						margin-right: 6px;
						position: absolute;
						top: -1px;
						left: 0;
						transition: left 0.1s ease-in-out;
					}

					&:hover {
						text-decoration: underline;

						&::before {
							left: 3px;
						}
					}
				}
			}

			.js-accordion_content {
				opacity: 1;
				max-height: none;
			}
		}
	}

	&__bottom_list_area {
		$padding: 5px;

		background-color: #ffffff;
		margin-top: 2em;
		position: relative;
		top: 0;
		left: 0;
		padding: 30px $padding 18px 40px;
		margin-left: -$padding;
		margin-right: -$padding;
		// padding-left: $padding;

		.a1 {
			position: absolute;
			top: -0.7em;
			left: $padding + 10px;
		}
	}

	&__list-bottom {
		.li1-attempt {
			display: block;
		}

		.sub_menu__ul2 {
			display: block;
		}

		.li2 {
			margin-bottom: 10px;
		}

		.a2 {
			display: block;
			font-size: 1rem;
			color: var.palette("blue", "color");
			padding-left: 20px;
			position: relative;
			top: 0;
			left: 0;
			line-height: 1.3;
			border-radius: 0.2em;
			transition: background-color 0.2s var.$easeOutQuint;

			&::before {
				@include mixin_common.bg-block("../img/icon/arrow/link2.webp", 6px, 9px);

				position: absolute;
				top: 0.45em;
				left: 5px;
			}

			&:hover {
				background-color: var.palette("link", "hover_bg");
			}
		}
	}

	&__content_list_area {
		&._department {
			max-height: 445px;
			overflow-y: scroll;
		}
	}

	&__top_list_area {
		&._about {
			// padding: 0 20px;
			// border-radius: 15px;
			border-bottom: 1px solid #8a92a2;

			.top_inner {
				width: 100%;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;

				@media #{var.$lg2} {
					display: block;
				}
			}

			.about_link {
				@media #{var.$lg2} {
					min-width: 270px;
					display: none;
				}

				> .title {
					padding-right: 90px;
					color: #111111;
					font-size: var.$fontsize20;
					letter-spacing: 0.05em;
					// max-width: 1000px;
					margin: 0 auto;
					display: flex;
					position: relative;
					align-items: center;
					margin-bottom: 20px;
					padding-top: 17px;
					font-weight: bold;

					&:hover {
						&::after {
							transform: translateX(8px);
						}
					}

					@media #{var.$lg2} {
						display: none;
						// padding-top: 20px;
						// padding-bottom: 20px;
					}

					&::after {
						content: "";
						display: inline-block;
						background: url("../img/icon/arrow/circle_blue.webp") 0 0 no-repeat transparent;
						background-size: 32px 32px;
						width: 32px;
						height: 32px;
						position: absolute;
						left: 200px;
						transition: transform 0.2s;
						bottom: 0;

						@media #{var.$lg2} {
							bottom: 2px;
						}
					}

					img {
						margin-right: 14px;
						width: 43px;
						height: auto;
					}
				}
			}

			.gnav_sub_about_top_list {
				display: flex;
				grid-template-columns: 1fr 1fr;
				flex-wrap: wrap;

				@media #{var.$lg2} {
					// column-gap: 35px;
					align-items: center;
					justify-content: space-between;
				}

				@media #{var.$lg1260} {
					// column-gap: 20px;
				}

				li {
					padding-top: 20px;

					@media #{var.$lg2} {
						padding: 20px 0;
					}

					&:first-of-type {
						display: none;

						@media #{var.$lg2} {
							display: block;
							font-weight: bold;
							padding-right: 120px;
						}

						@media (width <= 1100px) {
							padding-right: 30px;
						}
					}

					> .btn {
						margin-bottom: 0;

						@media #{var.$lg2} {
							width: 100%;
						}

						&::after {
							right: 0;
						}
					}
				}
			}
		}
	}
}

.swiper-slide {
	&._department {
		.gnav_sub__l {
			position: relative;
			max-height: 500px;
			padding-bottom: 30px;
		}
	}
}

//.gnav_sub_thumb_list {
//	//@include mixin_common.columns(3, 20px);
//	display: grid;
//	grid-template-columns: 1fr 1fr 1fr;
//	column-gap: 55px;

//	@media #{var.$lg2} {
//		column-gap: 35px;
//	}

//	@media #{var.$lg1260} {
//		column-gap: 20px;
//	}

//	li {
//		border-radius: 10px;
//	}

//	a {
//		display: block;
//		width: 300px;
//		margin: auto;
//		padding-bottom: 10px;

//		@media #{var.$lg2} {
//			max-width: 300px;
//			width: 100%;
//		}

//		.image {
//			border-top-left-radius: 10px;
//			border-top-right-radius: 10px;

//			img {
//				display: block;
//				border-top-left-radius: inherit;
//				border-top-right-radius: inherit;
//			}
//		}

//		.title {
//			display: flex;
//			align-items: center;
//			justify-content: space-between;
//			line-height: 1.2;
//			border-bottom-left-radius: 10px;
//			border-bottom-right-radius: 10px;
//			border-bottom: 1px solid #dfe5ed;
//			border-left: 1px solid #dfe5ed;
//			border-right: 1px solid #dfe5ed;
//			min-height: 55px;
//			padding: 10px 25px;
//			color: #111111;
//			letter-spacing: 0.05em;
//			box-sizing: border-box;

//			&::after {
//				content: "";
//				display: inline-block;
//				flex-shrink: 0;
//				background: url("../img/icon/arrow/circle_green_mini.webp") 0 0 no-repeat transparent;
//				background-size: 22px 22px;
//				width: 22px;
//				height: 22px;
//				transition: transform 0.2s;
//			}
//		}

//		&:hover {
//			.title {
//				&::after {
//					transform: translateX(8px);
//				}
//			}
//		}
//	}
//}
