@charset "UTF-8";
/* stylelint-disable at-rule-empty-line-before */
/* simple clearfix */
@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
@keyframes _on_opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes header_index_show {
  0% {
    display: none;
  }
  1% {
    display: block;
    opacity: 0;
    top: -30px;
  }
  100% {
    top: 0;
    opacity: 1;
    display: block;
  }
}
@keyframes header_index_hide {
  0% {
    top: 0;
    opacity: 1;
    display: block;
  }
  99% {
    top: -30px;
    opacity: 0;
    display: block;
  }
  100% {
    top: -30px;
    opacity: 0;
    display: none;
  }
}
@keyframes header_search_show {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(64px);
  }
}
@keyframes header_search_hide {
  0% {
    transform: translateY(64px);
  }
  100% {
    transform: translateY(0);
  }
}
/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/
.root {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}
.root._bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  height: 100%;
  background-color: rgba(17, 17, 17, 0.1254901961);
  pointer-events: none;
  z-index: 1;
}

.pc_container,
.ipc_container,
.pc_tb_container,
.container {
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1260px;
}
.pc_container._relative,
.ipc_container._relative,
.pc_tb_container._relative,
.container._relative {
  position: relative;
  top: 0;
  left: 0;
}
.pc_container._md,
.ipc_container._md,
.pc_tb_container._md,
.container._md {
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1060px;
}
.pc_container._sm,
.ipc_container._sm,
.pc_tb_container._sm,
.container._sm {
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}
.pc_container._sm2,
.ipc_container._sm2,
.pc_tb_container._sm2,
.container._sm2 {
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.pc_padding,
.padding {
  padding-left: 30px;
  padding-right: 30px;
}

.main_flex_area {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.main_flex_area > * {
  min-width: 0;
  max-width: 100%;
  width: 100%;
}
.main_flex_area._department_detail {
  padding-top: 20px;
}

.main_column {
  order: 1;
  width: 100%;
  box-sizing: border-box;
  padding-left: 60px;
}
@media screen and (max-width: 1199px) {
  .main_column {
    padding-left: 30px;
  }
}

.sub_column {
  order: 2;
  width: 280px;
  flex-shrink: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.main_content {
  font-size: 1rem;
  margin-bottom: 120px;
}

.scroll_to_top {
  right: 0;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

*/
.pc_none,
.root .pc_none {
  display: none;
}

.block,
.pc_block,
.ipc_block {
  display: block;
}

.xs_block {
  display: none;
}

@media screen and (width >= 1200px), print {
  .lg_block {
    display: block;
  }
}
@media screen and (width >= 1000px) and (width <= 1099px), print {
  .pc_s_block {
    display: block;
  }
}
.tel_link,
.tel_link-no {
  pointer-events: none;
  cursor: default;
  color: inherit;
}
.root .tel_link, .root .tel_link:hover,
.root .tel_link-no,
.root .tel_link-no:hover {
  text-decoration: none;
  color: inherit;
}

.sp_click,
.pc_no_click {
  pointer-events: none;
  cursor: default;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************



## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/
/**************************

## - 段組設定 2列 3列 4列

PCの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .pc_column2**

	<ul class="pc_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .pc_column3**

	<ul class="pc_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .pc_column4**

	<ul class="pc_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/
.pc_column2 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;
  width: calc(100% + 20px);
}
.pc_column2 > * {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  width: 50%;
  margin-bottom: 20px;
}

.pc_column3 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;
  width: calc(100% + 20px);
}
.pc_column3 > * {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  width: 33.33%;
  margin-bottom: 20px;
}

.pc_column4 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;
  width: calc(100% + 20px);
}
.pc_column4 > * {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  width: 25%;
  margin-bottom: 20px;
}

.pc_column2b::before, .pc_column2b::after {
  content: "";
  display: table;
}
.pc_column2b::after {
  clear: both;
}
.pc_column2b > * {
  width: 50%;
  float: left;
  box-sizing: border-box;
}
.pc_column2b > *:nth-child(2n+1) {
  clear: left;
  padding-right: 15px;
}
.pc_column2b > *:nth-child(2n) {
  padding-left: 15px;
}

.pc_column3b::before, .pc_column3b::after {
  content: "";
  display: table;
}
.pc_column3b::after {
  clear: both;
}
.pc_column3b > * {
  width: 33.3333333333%;
  float: left;
  box-sizing: border-box;
}
.pc_column3b > *:nth-child(3n+1) {
  clear: left;
  padding-right: 13px;
}
.pc_column3b > *:nth-child(3n+2) {
  padding-left: 6px;
  padding-right: 6px;
}
.pc_column3b > *:nth-child(3n) {
  padding-left: 13px;
}

.pc_column4b::before, .pc_column4b::after {
  content: "";
  display: table;
}
.pc_column4b::after {
  clear: both;
}
.pc_column4b > * {
  width: 25%;
  float: left;
  box-sizing: border-box;
}
.pc_column4b > *:nth-child(4n+1) {
  clear: left;
  padding-right: 15px;
}
.pc_column4b > *:nth-child(4n+2) {
  padding-left: 5px;
  padding-right: 10px;
}
.pc_column4b > *:nth-child(4n+3) {
  padding-left: 10px;
  padding-right: 5px;
}
.pc_column4b > *:nth-child(4n) {
  padding-left: 15px;
}

.pc_column2 img,
.pc_column3 img,
.pc_column4 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  vertical-align: bottom;
}

.set {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.set > * {
  flex-grow: 5;
}
.set > *:not(.fix) {
  margin-top: -10px;
}
.set > .left {
  order: 1;
}
.set > .right {
  order: 2;
}
.set > .fix {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
}
.set > .flex {
  display: flex;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/
.header {
  height: 120px;
  backdrop-filter: blur(10px) brightness(107%);
  background-color: rgb(255, 255, 255);
  color: #111111;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 4px 5px 8px 0 rgba(111, 133, 155, 0.1254901961);
  transition: top 0.2s;
}
.header._open_search {
  top: 64px;
  border-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.header._open_search .header__search_input_area {
  opacity: 1;
  pointer-events: all;
}
@supports not (backdrop-filter: blur(30px)) {
  .header {
    background-color: red;
  }
}
.header:not(._scroll)._show_sub_menu {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom: 1px solid #eeeeee;
}
.header__t {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}
.header__t .header__l {
  flex-shrink: 0;
}
.header__t .header__r {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__b {
  display: flex;
  justify-content: center;
}
.header__r {
  width: 100%;
}
.header__logo {
  width: 445px;
  margin-right: 50px;
}
@media screen and (max-width: 1259px) {
  .header__logo {
    width: 300px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .header__logo {
    width: 270px;
    margin-right: 15px;
  }
}
.header__action_list {
  display: flex;
  align-items: center;
}
.header__action_list > li {
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 3px;
  position: relative;
}
.header__action_list > li:not(:last-of-type) {
  margin-right: 12px;
}
@media screen and (max-width: 1439px) {
  .header__action_list > li:not(:last-of-type) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 1259px) {
  .header__action_list > li:not(:last-of-type) {
    margin-right: 5px;
  }
}
.header__action_list > li:last-of-type {
  margin-right: 33px;
}
@media screen and (max-width: 1439px) {
  .header__action_list > li:last-of-type {
    margin-right: 25px;
  }
}
@media screen and (max-width: 1259px) {
  .header__action_list > li:last-of-type {
    margin-right: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .header__action_list > li:last-of-type {
    margin-right: 5px;
  }
}
.header__action_list > li span {
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: #111111;
  font-size: 0.875rem;
  margin-right: 8px;
}
@media screen and (max-width: 1199px) {
  .header__action_list > li span {
    font-size: 0.813rem;
    margin-right: 5px;
    letter-spacing: 0.03em;
  }
}
.header__action_list > li._language a {
  position: relative;
  display: flex;
  align-items: center;
  color: #111111;
}
.header__action_list > li._language img {
  width: 33px;
  height: auto;
}
@media screen and (max-width: 1199px) {
  .header__action_list > li._language img {
    width: 28px;
  }
}
.header__action_list > li._font_size {
  display: flex;
  align-items: center;
}
.header__action_list > li._font_size .fontsize_list {
  display: flex;
  align-items: center;
}
.header__action_list > li._font_size .fontsize_list > ._s,
.header__action_list > li._font_size .fontsize_list > ._l {
  position: relative;
}
.header__action_list > li._font_size .fontsize_list > ._s:not(._selected) a,
.header__action_list > li._font_size .fontsize_list > ._l:not(._selected) a {
  position: relative;
  width: 33px;
  height: 33px;
  background-color: transparent;
  border-radius: 50%;
  color: transparent;
  background-image: linear-gradient(to right, #2a8b8e, #61a256);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 100;
  cursor: pointer;
  transition: opacity 0.2s;
}
@media screen and (max-width: 1199px) {
  .header__action_list > li._font_size .fontsize_list > ._s:not(._selected) a,
  .header__action_list > li._font_size .fontsize_list > ._l:not(._selected) a {
    width: 29px;
    height: 29px;
  }
}
.header__action_list > li._font_size .fontsize_list > ._s:not(._selected) a:hover,
.header__action_list > li._font_size .fontsize_list > ._l:not(._selected) a:hover {
  opacity: 0.7;
}
.header__action_list > li._font_size .fontsize_list > ._s:not(._selected)::before,
.header__action_list > li._font_size .fontsize_list > ._l:not(._selected)::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 31px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  z-index: 5;
}
@media screen and (max-width: 1199px) {
  .header__action_list > li._font_size .fontsize_list > ._s:not(._selected)::before,
  .header__action_list > li._font_size .fontsize_list > ._l:not(._selected)::before {
    width: 27px;
    height: 27px;
  }
}
.header__action_list > li._font_size .fontsize_list > ._s:not(._selected)::after,
.header__action_list > li._font_size .fontsize_list > ._l:not(._selected)::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 33px;
  height: 33px;
  background: linear-gradient(to right, #2a8b8e, #61a256);
  border-radius: 50%;
  z-index: 1;
}
@media screen and (max-width: 1199px) {
  .header__action_list > li._font_size .fontsize_list > ._s:not(._selected)::after,
  .header__action_list > li._font_size .fontsize_list > ._l:not(._selected)::after {
    width: 29px;
    height: 29px;
  }
}
.header__action_list > li._font_size .fontsize_list > ._s._selected a,
.header__action_list > li._font_size .fontsize_list > ._l._selected a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e7edf1;
}
@media screen and (max-width: 1199px) {
  .header__action_list > li._font_size .fontsize_list > ._s._selected a,
  .header__action_list > li._font_size .fontsize_list > ._l._selected a {
    width: 27px;
    height: 27px;
  }
}
.header__action_list > li._font_size .fontsize_list > ._s {
  margin-right: 10px;
}
.header__action_list > li._search {
  margin-left: 5px;
}
.header__action_list > li._search > a {
  transition: 0.2s;
}
.header__action_list > li._search > a span {
  display: flex;
  align-items: center;
}
.header__action_list > li._search > a span::after {
  display: block;
  content: "";
  width: 33px;
  height: 33px;
  background: url("../img/icon/search.webp");
  background-size: contain;
  margin-left: 8px;
}
.header__action_list > li._search > a:hover {
  opacity: 0.7;
}
.header__action_list > li._search.hidden > span::after {
  display: none;
}
.header__action_list > li:hover .action_list_detail {
  display: block;
}
.header__search_input_area {
  background-color: #439574;
  height: 64px;
  width: 100%;
  position: absolute;
  top: -64px;
  left: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: opacity 0.2s;
  opacity: 0;
  pointer-events: none;
}
.header__search_input_area .container {
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
}
.header__search_input_area .container > * {
  min-width: 0;
  max-width: 100%;
  width: 100%;
}
.header__search_input_area .container > .left .search__text {
  color: #ffffff;
  background-color: #0d3b40;
  border: 0 none;
  padding: 0 10px 0 65px;
}
.header__search_input_area .container > .left .search__text:focus {
  outline: 1px rgba(255, 255, 255, 0.2) solid;
}
.header__search_input_area .container > .left .search__submit {
  right: auto;
  left: 0;
  border-left: 0 none;
  background-image: url("../img/icon/header_search_input.png");
  background-size: contain;
  width: 54px;
  height: 40px;
}
.header__search_input_area .container > .right {
  flex: 0 0 64px;
}
.header__search_input_area .container > .right a {
  display: block;
  width: 64px;
  height: 64px;
  position: relative;
  top: 0;
  left: 0;
}
.header__search_input_area .container > .right a img {
  display: block;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
.header__link_list {
  display: flex;
  align-items: center;
}
.header__link_list > li {
  width: 130px;
}
@media screen and (max-width: 1439px) {
  .header__link_list > li._department {
    width: 115px;
  }
}
@media screen and (max-width: 1199px) {
  .header__link_list > li._department {
    width: 115px;
  }
}
@media screen and (max-width: 1439px) {
  .header__link_list > li {
    width: 100px;
  }
}
@media screen and (max-width: 1199px) {
  .header__link_list > li {
    width: 95px;
  }
}
.header__link_list > li:not(:last-of-type) {
  margin-right: 8px;
}
.header__link_list > li a {
  height: 34px;
  padding: 9px 5px;
  display: block;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 1.2;
  color: #213d2c;
  background-color: #eef2f0;
  box-sizing: border-box;
  font-size: 0.875rem;
  border-radius: 17px;
  transition: color, background-color 0.3s;
}
@media screen and (max-width: 1199px) {
  .header__link_list > li a {
    font-size: 0.813rem;
    border-radius: 15px;
  }
}
.header__link_list > li a:hover {
  background-color: #213d2c;
  color: #eef2f0;
}

.gnav__ul {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.gnav__ul > * {
  min-width: 0;
  max-width: 100%;
}
.gnav__li {
  flex-grow: 10;
  display: flex;
  position: relative;
  pointer-events: none;
}
.gnav__li:not(:last-child)::after {
  content: "";
  display: block;
  width: 1px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  background: #b2b2b2;
}
.gnav__li:first-of-type > a {
  padding-left: 45px;
}
@media screen and (max-width: 1439px) {
  .gnav__li:first-of-type > a {
    padding-left: 40px;
  }
}
@media (width <= 1200px) {
  .gnav__li:first-of-type > a {
    padding-left: 15px;
  }
}
.gnav__li:last-of-type > a {
  padding-right: 45px;
}
@media screen and (max-width: 1439px) {
  .gnav__li:last-of-type > a {
    padding-right: 40px;
  }
}
@media (width <= 1200px) {
  .gnav__li:last-of-type > a {
    padding-right: 15px;
  }
}
.gnav__a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111111;
  line-height: 1.4;
  letter-spacing: 0.05em;
  text-align: center;
  font-size: 19px;
  padding: 2px 30px 15px;
  pointer-events: all;
  transition: font-weight 0.2s;
  width: 100%;
}
.gnav__a::after {
  content: "";
  flex-shrink: 0;
  display: inline-block;
  background: url("../img/icon/arrow/circle_blue_02.webp") 0 0 no-repeat transparent;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  margin-left: 13px;
  transition: transform 0.2s;
}
@media screen and (max-width: 1439px) {
  .gnav__a {
    padding: 2px 20px;
    font-size: 17px;
  }
}
@media screen and (max-width: 1199px) {
  .gnav__a {
    padding: 2px 15px;
    font-size: 15px;
  }
}
.gnav__a:hover {
  color: #439574;
}
.gnav__a:hover::after {
  transform: translateX(6px);
}

.gnav_sub_area {
  z-index: 1;
  height: 0;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0 0 20px 20px;
}
.gnav_sub_area .container {
  padding-left: 60px;
  padding-right: 60px;
  max-width: 1440px;
}
@media screen and (max-width: 1199px) {
  .gnav_sub_area .container {
    padding: 0 40px;
  }
}
@media (width <= 1173px) {
  .gnav_sub_area .container {
    padding: 0 30px;
  }
}
.gnav_sub_area .container::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #eeeeee; /* borderの色を指定 */
}
.gnav_sub_area._show {
  height: auto;
  display: block;
  animation-name: fade-in;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
  background-color: #ffffff;
}
.gnav_sub_area._leave {
  height: auto;
  display: block;
  animation-name: fade-out;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
}

.gnav_sub {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding-top: 5px;
  padding-bottom: 30px;
}
.gnav_sub__l {
  flex-shrink: 0;
  width: 320px;
  padding-top: 70px;
  padding-right: 55px;
  border-right: 1px solid #ededed;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1439px) {
  .gnav_sub__l {
    padding-right: 35px;
    width: 300px;
  }
}
@media screen and (max-width: 1259px) {
  .gnav_sub__l {
    padding-right: 25px;
    width: 270px;
  }
}
.gnav_sub__l .title {
  margin-bottom: 30px;
}
.gnav_sub__l .title a {
  display: inline-block;
  font-size: 1.438rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  border-bottom: 2px solid #111111;
  padding-bottom: 20px;
  margin-bottom: 15px;
  width: 100%;
  color: #111111;
}
@media screen and (max-width: 1259px) {
  .gnav_sub__l .title a {
    font-size: 1.25rem;
  }
}
.gnav_sub__l .title a:hover {
  opacity: 0.6;
}
.gnav_sub__l .title .ja {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.438rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  border-bottom: 1px solid #000000;
  padding-bottom: 20px;
  margin-bottom: 15px;
  width: 100%;
}
.gnav_sub__l .title .ja::after {
  content: "";
  display: inline-block;
  background: url("../img/icon/arrow/circle_blue.webp") 0 0 no-repeat transparent;
  background-size: 33px 33px;
  width: 33px;
  height: 33px;
}
.gnav_sub__l .title .en {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  letter-spacing: 0.05em;
  font-size: 1.25rem;
  text-align: end;
  background-image: linear-gradient(to right, #2a8b8e, #61a256);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.gnav_sub__l .gnav_close_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  margin: 0 auto;
  border: 1px solid #dfe5ed;
  border-radius: 35px;
  padding: 15px 10px;
  color: #111111;
  box-sizing: border-box;
  transition: background-color 0.2s;
}
.gnav_sub__l .gnav_close_btn::before {
  content: "";
  display: inline-block;
  background: url("../img/icon/close.webp") 0 0 no-repeat transparent;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.gnav_sub__l .gnav_close_btn:hover {
  background-color: #e8ecf1;
}
.gnav_sub__r {
  width: 100%;
  box-sizing: border-box;
}
.gnav_sub__list {
  font-size: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 55px;
}
@media screen and (max-width: 1439px) {
  .gnav_sub__list {
    column-gap: 35px;
  }
}
@media screen and (max-width: 1259px) {
  .gnav_sub__list {
    column-gap: 20px;
  }
}
.gnav_sub__list .li1._menu {
  width: 50%;
}
.gnav_sub__list .li1-attempt {
  display: none;
}
.gnav_sub__list .a1 {
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #111111;
  position: relative;
  top: 0;
  left: 0;
  line-height: 1.3;
  letter-spacing: 0;
  transition: background-color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #ededed;
}
@media screen and (max-width: 1259px) {
  .gnav_sub__list .a1 {
    font-size: 0.938rem;
  }
}
.gnav_sub__list .a1:hover ._inner::after {
  transform: translateX(8px);
}
.gnav_sub__list .a1 .gnav_block {
  display: block;
}
.gnav_sub__list .a1.ext > ._inner::after {
  display: none;
}
.gnav_sub__list .icon {
  width: 45px;
  flex-shrink: 0;
  margin-right: 18px;
}
.gnav_sub__list ._inner {
  letter-spacing: 0.05em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.gnav_sub__list ._inner::after {
  content: "";
  flex-shrink: 0;
  display: inline-block;
  background: url("../img/icon/arrow/circle_blue.webp") 0 0 no-repeat transparent;
  background-size: 22px 22px;
  width: 22px;
  height: 22px;
  transition: transform 0.2s;
  margin-left: 10px;
}
.gnav_sub__list .js-accordion_toggle,
.gnav_sub__list .sub_menu__ul2 {
  display: none;
}
.gnav_sub__list._dock > .li1 {
  width: 100%;
}
.gnav_sub__list._dock > .li1:not(:last-child) {
  margin-bottom: 25px;
}
.gnav_sub__list._dock .sub_menu__ul2 {
  padding-left: 7px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
}
.gnav_sub__list._dock .sub_menu__ul2 .li2 {
  margin-top: 3px;
  min-width: 25%;
  box-sizing: border-box;
  padding-right: 20px;
}
.gnav_sub__list._dock .sub_menu__ul2 .a2 {
  font-size: 0.938rem;
  color: #ffffff;
  position: relative;
  top: 0;
  left: 0;
  padding-left: 26px;
}
.gnav_sub__list._dock .sub_menu__ul2 .a2::before {
  content: "";
  display: inline-block;
  background: url("../img/icon/arrow/link3.webp") 0 center no-repeat transparent;
  background-size: 18px 18px;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  position: absolute;
  top: -1px;
  left: 0;
  transition: left 0.1s ease-in-out;
}
.gnav_sub__list._dock .sub_menu__ul2 .a2:hover {
  text-decoration: underline;
}
.gnav_sub__list._dock .sub_menu__ul2 .a2:hover::before {
  left: 3px;
}
.gnav_sub__list._dock .js-accordion_content {
  opacity: 1;
  max-height: none;
}
.gnav_sub__bottom_list_area {
  background-color: #ffffff;
  margin-top: 2em;
  position: relative;
  top: 0;
  left: 0;
  padding: 30px 5px 18px 40px;
  margin-left: -5px;
  margin-right: -5px;
}
.gnav_sub__bottom_list_area .a1 {
  position: absolute;
  top: -0.7em;
  left: 15px;
}
.gnav_sub__list-bottom .li1-attempt {
  display: block;
}
.gnav_sub__list-bottom .sub_menu__ul2 {
  display: block;
}
.gnav_sub__list-bottom .li2 {
  margin-bottom: 10px;
}
.gnav_sub__list-bottom .a2 {
  display: block;
  font-size: 1rem;
  color: #102b50;
  padding-left: 20px;
  position: relative;
  top: 0;
  left: 0;
  line-height: 1.3;
  border-radius: 0.2em;
  transition: background-color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.gnav_sub__list-bottom .a2::before {
  content: "";
  display: block;
  background: url("../img/icon/arrow/link2.webp") 0 0 no-repeat transparent;
  background-size: 6px 9px;
  width: 6px;
  height: 9px;
  position: absolute;
  top: 0.45em;
  left: 5px;
}
.gnav_sub__content_list_area._department {
  max-height: 445px;
  overflow-y: scroll;
}
.gnav_sub__top_list_area._about {
  border-bottom: 1px solid #8a92a2;
}
.gnav_sub__top_list_area._about .top_inner {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1439px) {
  .gnav_sub__top_list_area._about .top_inner {
    display: block;
  }
}
@media screen and (max-width: 1439px) {
  .gnav_sub__top_list_area._about .about_link {
    min-width: 270px;
    display: none;
  }
}
.gnav_sub__top_list_area._about .about_link > .title {
  padding-right: 90px;
  color: #111111;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  margin: 0 auto;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 17px;
  font-weight: bold;
}
.gnav_sub__top_list_area._about .about_link > .title:hover::after {
  transform: translateX(8px);
}
@media screen and (max-width: 1439px) {
  .gnav_sub__top_list_area._about .about_link > .title {
    display: none;
  }
}
.gnav_sub__top_list_area._about .about_link > .title::after {
  content: "";
  display: inline-block;
  background: url("../img/icon/arrow/circle_blue.webp") 0 0 no-repeat transparent;
  background-size: 32px 32px;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 200px;
  transition: transform 0.2s;
  bottom: 0;
}
@media screen and (max-width: 1439px) {
  .gnav_sub__top_list_area._about .about_link > .title::after {
    bottom: 2px;
  }
}
.gnav_sub__top_list_area._about .about_link > .title img {
  margin-right: 14px;
  width: 43px;
  height: auto;
}
.gnav_sub__top_list_area._about .gnav_sub_about_top_list {
  display: flex;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
}
@media screen and (max-width: 1439px) {
  .gnav_sub__top_list_area._about .gnav_sub_about_top_list {
    align-items: center;
    justify-content: space-between;
  }
}
.gnav_sub__top_list_area._about .gnav_sub_about_top_list li {
  padding-top: 20px;
}
@media screen and (max-width: 1439px) {
  .gnav_sub__top_list_area._about .gnav_sub_about_top_list li {
    padding: 20px 0;
  }
}
.gnav_sub__top_list_area._about .gnav_sub_about_top_list li:first-of-type {
  display: none;
}
@media screen and (max-width: 1439px) {
  .gnav_sub__top_list_area._about .gnav_sub_about_top_list li:first-of-type {
    display: block;
    font-weight: bold;
    padding-right: 120px;
  }
}
@media (width <= 1100px) {
  .gnav_sub__top_list_area._about .gnav_sub_about_top_list li:first-of-type {
    padding-right: 30px;
  }
}
.gnav_sub__top_list_area._about .gnav_sub_about_top_list li > .btn {
  margin-bottom: 0;
}
@media screen and (max-width: 1439px) {
  .gnav_sub__top_list_area._about .gnav_sub_about_top_list li > .btn {
    width: 100%;
  }
}
.gnav_sub__top_list_area._about .gnav_sub_about_top_list li > .btn::after {
  right: 0;
}

.swiper-slide._department .gnav_sub__l {
  position: relative;
  max-height: 500px;
  padding-bottom: 30px;
}